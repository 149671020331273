import {useState, useEffect} from 'react';
import './Footer.css';
import logo from './Img/bnb-logo.png';
import techrate from './Img/techrate.png';
import haze from './Img/haze2.png';

export default function Footer({}) {
  return (
       <div className="footer-container">
          <div>
            <p>Powered by</p>
            <a href="https://www.binance.org/en/smartChain" target="_blank">Binance Smart Chain</a>
          </div>

          <div>
              <div className="partners-wrap-ft">
                <div>
                  <img onClick={() => window.open("https://hazecrypto.net/wp-content/uploads/2021/03/HazeSecurity_BNBMinter.pdf")}
                   src={haze} style={{width:60, opacity: 0.7, marginRight: 10}} />
                </div>
                <div>
                  <img  onClick={() => window.open("https://github.com/TechRate/Smart-Contract-Audits/blob/main/BNBMinter.pdf")}
                   src={techrate} />
                </div>
            </div>
          </div>

          <div>
            <p>© 2021 by AV. All rights reserved.</p>
            <a href="" target="_blank">www.BNBMinter.app</a>
          </div>
      </div>
  );
}
