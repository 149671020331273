import {useState, useEffect, useMemo} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import icon from './Img/banca-icon.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Staking.css';
import logo from './Img/bnb-logo.png';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import Countdown from 'react-countdown';
import {
        getAllowance, 
        approveContract, 
        userDividends, 
        getPercent, 
        getProfit,
        getUserDepositInfo,
        getCreditBalance,
        getCreditPaid,
        withdraw,
        invest,
        getCredit,
        terminate,
        getTokenBalance,
        getPrice,
        getPriceBMX
        } 
        from '../../Tools/StakingTools';
        import { totalStaked, getPriceBNB, 
          } from '../../Tools/methods';

import Web3 from "web3";
const web3 = new Web3(window.ethereum);

export default function Staking({}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState(0);
  const [disabled, setDisabled] = useState(0);
  const [userEarnings, setUserEarnings] = useState(0);
  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState('');
  const [profit, setProfit] = useState(0);
  const [depositInfo, setDepositInfo] = useState(null);
  const [credit, setCredit] = useState(0);
  const [creditReceived, setCreditReceived] = useState(0);
  const [BMXBalance, setBMXBalance] = useState(0);
  const [price, setPrice] = useState(0);
  const [BNBPrice, setBNBPrice] = useState(0);
  const [totalStakedBNB, settotalStaked] = useState(0);
  let [counter, setCounter] = useState(0);

  const getTokenAllowance = async () => {
       const allowance =  await getAllowance(wallet);
       setAllowance(allowance);
  }

  const getUserDividends = async() => {
    const response =  await userDividends(wallet);
    setUserEarnings(response);
  }

  const getPlanPercent = async() => {
    const response =  await getPercent();
    setPercent(response);
  }

  const getUserInfo = async() => {
    const response =  await getUserDepositInfo(wallet);
    setDepositInfo(response);
  }

  const getCreditData = async() => {
    const price =  await getPriceBNB();
    setBNBPrice(price)
    const response =  await totalStaked();
    settotalStaked(response * price);
    const balance =  await getCreditBalance(wallet);
    const paid =  await getCreditPaid(wallet);
    setCredit(balance);
    setCreditReceived(paid);
  }

  const getBMXBalance = async() => {
    const balance =  await getTokenBalance(wallet);
    if(balance) setBMXBalance(balance);
    setLoading(false);
  }

  const getBMXPrice = async() => {
    const price =  await getPriceBMX();
    setPrice(price);
  }

  const handleChange = async (e) => {
      setAmount(e.target.value);
      const response =  await getProfit(e.target.value);
      setProfit(response);
  }

  const stake = async () => {
    if(disabled || amount < 100) return null;
    setDisabled(true);
    try {
       const txData = await invest(wallet, amount);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    setAmount('');
    } catch (error) {
      setDisabled(false);
      setAmount('');
      console.log('tx error', error);
    }
  }

  const getCreditFunc = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await getCredit(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log('tx error', error);
    }
  }
  
  const terminateStake = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await terminate(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log('tx error', error);
    }
  }


  const withdrawRewards = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await withdraw(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log('tx error', error);
    }
  }

  const approve = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await approveContract(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
       console.log(hash);
    })
      setDisabled(false);
    } catch (error) {
      setDisabled(false)
      console.log('tx error', error);
    }
  }


  useEffect(() => {
    getTokenAllowance();
    getUserDividends();
    getPlanPercent();
    getUserInfo();
    getCreditData();
    getBMXBalance();
    getBMXPrice();
  },[disabled, counter])

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter++);
    }, 10000);
    return () => {
      clearInterval(timer);
    }
  }, [])


  const getTimesLeft = () => {
    var a = moment();
    var b = moment.unix([1617789338]);
    const secondLeft = b.diff(a, 'second')   // =1
    //console.log('hour', hoursLeft, minutesLeft)
    return secondLeft * 1000;
  }
  
  
  
  return (
        loading ? 
         <div className="loading-div">
           <img src={icon} />
         </div>
       :
       <div>
          <Header BMXbalance={BMXBalance} token={true} BNBPrice={BNBPrice} totalStaked={totalStakedBNB} />
          <div className="bmx-staking-container">
                <h3 style={{color: '#bd64c0'}}>Stake BMX - Earn 
                <span style={{color: "#d6ccff", fontSize:30}}>{" " + percent + "% "}</span>Daily</h3>
          </div>
          <div className="bmx-staking-wrap">
                <div className="bmx-staking-box">
                    <p className="earned-amount-bmx">
                      <CountUp
                        start={userEarnings}
                        end={userEarnings}
                        separator=","
                        decimals={4}
                        decimal="."
                        prefix=""
                        suffix=""
                        style={{
                          color: '#d6ccff',
                          fontSize: 40
                        }}
                        />
                    </p>
                    <span>BMX earned</span>
                    <button style={{opacity: disabled ? 0.3: 1}} onClick={withdrawRewards}
                     className="bmx-withdraw-btn">Withdraw</button>
                </div>
                <div className="bmx-staking-box">
                     <h5 className="bmx-price" style={{marginTop:5}}>1 BMX = <CountUp
                        start={price}
                        end={price}
                        separator=","
                        decimals={3}
                        decimal="."
                        prefix="$"
                        suffix=""
                        style={{
                          color: '#d6ccff',
                          fontSize: 20
                        }}
                        /></h5>
                    <p className="staked-amount-bmx">
                    <CountUp
                        start={depositInfo ? depositInfo.amount / (10 ** 18) : 0}
                        end={depositInfo ? depositInfo.amount / (10 ** 18) : 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        suffix=""
                        style={{
                          color: '#d6ccff',
                          fontSize: 40
                        }}
                        />
                    </p>
                    <span>BMX staked - 15 Days / {depositInfo ? depositInfo.percent / 10 : percent}%</span>
                    <input placeholder="100" value={amount} onChange={(e) => handleChange(e)} />
                    <span style={{marginTop:5}}>Profit - {<CountUp
                        start={profit}
                        end={profit}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        suffix=""
                        />} BMX</span>
                     {allowance < 1000000000 ? 
                       <button onClick={approve} style={{opacity: disabled ? 0.3: 1}}
                        className="bmx-withdraw-btn bmx-add-btn">APPROVE</button>
                       :
                       <button onClick={stake}
                         style={{opacity: disabled ? 0.3: 1}}
                         className="bmx-withdraw-btn bmx-add-btn">{!depositInfo ? 'Add Stake' : "Increase Stake"}</button>
                     }
                </div>
                <div className="bmx-staking-box bmx-credit-box">
                    <p className="credit-amount-bmx">
                    <CountUp
                        start={credit}
                        end={credit}
                        separator=","
                        decimals={4}
                        decimal="."
                        prefix=""
                        suffix=""
                        style={{
                          color: '#d6ccff',
                          fontSize: 35
                        }}
                        />
                    </p>
                    <span>credit balance</span>
                    <p className="credit-amount-bmx">
                    <CountUp
                        start={creditReceived}
                        end={creditReceived}
                        separator=","
                        decimals={4}
                        decimal="."
                        prefix=""
                        suffix=""
                        style={{
                          color: '#d6ccff',
                          fontSize: 35
                        }}
                        />
                    </p>
                    <span style={{marginBottom: 40}}>credit withdrawn</span>
                    <button onClick={getCreditFunc} style={{opacity: disabled ? 0.3: 1}}
                    className="bmx-withdraw-btn">Get Credit</button>
                </div>
          </div>
          <div className="terminate-stake-wrap">
            <span>* You can terminate your stake before the 15th day if you have not used the daily withdraw option</span>
            <span>* Early termination will attract a 20% fee from your initial deposit. e.g If you staked 100 BMX, you will get 80 BMX</span>
             <a className="read-more-link" href="https://bnbminter.medium.com/bmx-high-yield-reward-token-5d123590919a" target="_blank">How it works?</a>
             <button onClick={terminateStake} style={{opacity: disabled ? 0.3: 1}}
             className="bmx-withdraw-btn bmx-add-btn">Terminate Stake</button>
          </div>
      </div>
  );
}
