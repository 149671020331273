import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from '../src/screens/Home/Home';
import About from '../src/screens/About/About';
import Charts from '../src/screens/Charts/Charts';
import TokenScreen from '../src/screens/TokenScreen/TokenScreen';
import Staking from '../src/screens/Staking/Staking';
import Invest from '../src/screens/Token/Token';

function App() {
  return (
    <Router>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/how-it-works" exact component={About} />
      <Route path="/charts" exact component={Charts} />
      <Route path="/token/:id?" exact component={TokenScreen} />
      <Route path="/staking" exact component={Staking} />
      <Route path="/invest" exact component={Invest} />
    </Switch>
 </Router>
  );
}

export default App;
