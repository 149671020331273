
import  { contractABI} from './matrixxContractABI';
import  { pancakeRouterABI } from './pancakeRouterABI';
import plans from './plans';
import formatBigNumber from './formatBigNumber';
import Web3 from "web3";
const web3 = new Web3('https://polygon-rpc.com');
const StakingContract = '0x57293650501863Da6482cfda4C45dfc253Db2d3D';


export async function getAllowance(wallet) {
    try {
        if(wallet == null ) return 0;
        const contract = new web3.eth.Contract(contractABI, StakingContract);
        let allowance = await contract.methods.allowance(wallet, StakingContract);
            allowance = await allowance.call()
       // console.log('allowance ', allowance)
        return parseFloat(allowance);
    } catch (error) {
        console.log(error)
        return 0;
    }
}

//getAllowance('0xa89057e2424F6Db4a7DddBbD612D76b6C4844BD6');

export async function approveContract(wallet) {
    if(wallet == null) return;
    const amount = '100000000000000000000000000000000';
    const contract = new web3.eth.Contract(contractABI, StakingContract);
    let data = await contract.methods.approve(StakingContract, amount).encodeABI();
   // console.log(data)
    const transationData = { from: wallet, to: StakingContract, gas: '50000', value: "0", data}
    return transationData;
}

export async function getCreditBalance(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI, StakingContract);
        let credit = await contract.methods.creditBalance(wallet).call();
        console.log('CREDIT', credit)
        return parseFloat(credit / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function getCreditPaid(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI, StakingContract);
        let creditPaid = await contract.methods.getCreditReceived(wallet).call();
        console.log('CREDIT PAID', creditPaid)
        return parseFloat(creditPaid / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function getTokenBalance(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI, StakingContract);
        let balance = await contract.methods.balanceOf(wallet).call();
        return parseFloat(balance / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function totalStaked() {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.totalStaked().call();
        //console.log('total staked', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function contractBalance() {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getContractBalance().call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function userTotalStaked(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getUserTotalDeposits(wallet).call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function userDividends(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getUserDividends(wallet).call();
       // console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function getUserDepositInfo(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getUserDepositInfo(wallet, 0).call();
       // console.log('deposit', response)
        return response;
    } catch (error) {
        console.log(error);
        //return {}
    }
}

export async function getUserAmountOfDeposits(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let deposits = await contract.methods.getUserAmountOfDeposits(wallet).call();
        return deposits;
    } catch (error) {
        console.log(error);
        return 0;
    }
}

export async function getCredit(wallet) {
    const contract = new web3.eth.Contract(contractABI , StakingContract);
    let data = await contract.methods.getCredit().encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: StakingContract, gas: '430000', value: '0', data}
    return transationData;
}

export async function terminate(wallet) {
    const contract = new web3.eth.Contract(contractABI , StakingContract);
    let data = await contract.methods.unstake().encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: StakingContract, gas: '530000', value: '0', data}
    return transationData;
}



export async function getPercent(amount) {
    amount = formatBigNumber(Math.ceil(amount * (10 ** 18))).toString();
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
            let response = await contract.methods.getPercent(0).call();           
        return response / 10;
        
    } catch (error) {
        console.log(error);
        return 0;
    }
}

export async function getProfit(amount) {
    amount = formatBigNumber(Math.ceil(amount * (10 ** 18))).toString();
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
            let result = await contract.methods.getResult(0, amount).call();    
        return parseFloat(result.profit / (10 ** 18));
        
    } catch (error) {
        console.log(error);
        return 0;
    }
}

export async function totalReferer(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getUserReferralTotalBonus(wallet).call();
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function totalRefererWithDrawn(wallet) {
    try {
        const contract = new web3.eth.Contract(contractABI , StakingContract);
        let response = await contract.methods.getUserReferralWithdrawn(wallet).call();
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
        return 0;
    }
}

export async function invest(wallet, plan, amount) {
    const refWallet= localStorage.getItem('refa');
    amount = formatBigNumber(Math.ceil(amount * (10 ** 18))).toString();
     const contract = new web3.eth.Contract(contractABI , StakingContract);
    let data = await contract.methods.invest(refWallet, plan, amount).encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: StakingContract, gas: '530000', value: '0', data}
    return transationData;
}

export async function withdraw(wallet) {
     const contract = new web3.eth.Contract(contractABI , StakingContract);
    let data = await contract.methods.withdraw().encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: StakingContract, gas: '530000', value: '0', data}
    return transationData;
}


export async function getPrice() {
    try {
        const contract = new web3.eth.Contract(pancakeRouterABI, '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F');
        const path = [
                '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                '0x55d398326f99059fF775485246999027B3197955'
                ]
        let price = await contract.methods.getAmountsOut('1000000000000000000', path).call()
            price = price[1] / (10 ** 18);
            //console.log('price lp ', price.toFixed(2))
        return price;
    } catch (error) {
        
    }
}


export async function getPriceBMX() {
    try {
        const contract = new web3.eth.Contract(pancakeRouterABI, '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F');
        const path = [
                '0xD8Ab3285722CA8C78F92D636485495F54848EfF9',
                '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                '0x55d398326f99059fF775485246999027B3197955',
                ]
        let price = await contract.methods.getAmountsOut('1000000000000000000', path).call();
            price = price[2] / (10 ** 18);
            //console.log('price BMX ', price.toFixed(4))
        return price;
    } catch (error) {
        console.log(error);
        return 0;
    }
}

