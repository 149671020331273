import {useState, useEffect} from 'react';
import './Menu.css';
import logo from './Img/bnbminter-logo-green.png';
import Web3 from "web3";
import { FaFileContract } from 'react-icons/fa';
import { RiTelegramFill } from 'react-icons/ri';

export default function Menu({token, BMXbalance}) {
  const [loading, setloading] = useState(false);
  const [balance, setBalance] = useState(0);
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);

useEffect(() => {
  checkWalletChanged();
  getBalance();
})

//TRIM WALLET ADDRESS
const setWalletAddress = (wallet) => {
  var first5 = wallet.substr(0, 5); 
  var last5 = wallet.substr(wallet.length - 5); 
  return first5 + '...' + last5;
}

const checkWalletChanged  = () => {
  if (window.ethereum) {
      window.web3 = window.web3 = new Web3(Web3.currentProvider);
      window.ethereum.on('accountsChanged', (accounts) => {
          localStorage.setItem('wallet', accounts[0]);
          setWallet(accounts[0]);
          window.location.reload();
         // alert('changed')
      });
  }else localStorage.removeItem('wallet')
}

const getBalance = async () => {
      if(wallet != null && window.ethereum){
      let web3 = new Web3('https://bsc-dataseed1.binance.org:443');
       web3.eth.getBalance(wallet, function(err, result) {
        if (err) {
          console.log(err)
        } else {
          setBalance(result / (10**18))
        }
      })
    }
}

//CONNECT TO METAMASK
const _connectWalletMETAMASK = async () => {
  setloading(true);
  try {
    if (window.ethereum) {
        window.web3 = window.web3 = new Web3(Web3.currentProvider);
        const result = await window.ethereum.enable();
        console.log('catch error 1', result)
        //(result);
        if (result[0]) {
            console.log('wallet [0]', result[0])
             localStorage.setItem('wallet', result[0]);
             setWallet(result[0]);
             setloading(false);
             window.location.reload();
         }
          return true;
        } 
     else {
        setloading(false)
        localStorage.removeItem('wallet')
        return window.open('https://metamask.io/', '_blank');  
     }
     
 } catch (error) {
     console.log('catch error', error)
     setloading(false)
 }
}

  return (
    <div className="menu-container">
         <a style={{background: '#333FE8', color:'white'}} href="/">Dashboard</a>
          <a href="https://quickswap.exchange/#/swap?outputCurrency=0x57293650501863Da6482cfda4C45dfc253Db2d3D" target="_blank">Swap</a>
         <a href="invest">Invest</a>
         <a href="https://matrixx.gitbook.io/whitepaper/" target="_blank" >Documentation</a>
       </div>
  );
}
