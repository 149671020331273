import  { contractABI } from './contractABI';
import  { pancakeRouterABI } from './pancakeRouterABI';
import plans from './plans';
import axios from 'axios';
import formatBigNumber from './formatBigNumber';
import Web3 from "web3";
const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
const BNBMinterContract = '0x734Bf4623296dAb55de6Fced4D1395e6638e9D65';

const ids = 'julswap,pancakeswap-token,pancake-bunny,squirrel-finance,swipe,uniswap,vbswap,venus,wbnb,pizzaswap,crow-token,cheeseswap,cafeswap-token,bdollar,auto,bakerytoken,apecoin,acryptos,1inch,beefy-finance,berry-data,shopping-io,shield-protocol,goose-finance,binance-bitcoin'

export async function tokensList() {
    try {
    //    const response = await axios.get("https://api.coingecko.com/api/v3/coins/list?include_platform=true");
    //       response.data.forEach(token => {
    //         if(token.platforms['binance-smart-chain']) console.log(token);
    //    });
    const result =  await axios(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${ids}&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d`)
       //console.log(result.data)
       return result.data;
    } catch (error) {
        //console.log(error)
    }
}

