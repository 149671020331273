import {useState, useEffect} from 'react';
import './Header.css';
import logo from './Img/matrixx.png';
import airdropIcon from './Img/airdrop.png';
import Web3 from "web3";
import { FaFileContract } from 'react-icons/fa';
import { RiTelegramFill } from 'react-icons/ri';
import Menu from '../Menu/Menu';
import CountUp from 'react-countup';
import detectEthereumProvider from '@metamask/detect-provider'

let web3 = new Web3('https://polygon-rpc.com');


export default function Header({token, BMXbalance, TrixxPrice, totalStaked, setOpenModal, openModal}) {
  const [loading, setloading] = useState(false);
  const [balance, setBalance] = useState(0);
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);
  const [isNetwork, setIsNetwork] = useState(false);

useEffect(() => {
  checkWalletChanged();
  getBalance();
  getNetworkId()
})

//TRIM WALLET ADDRESS
const setWalletAddress = (wallet) => {
  var first5 = wallet.substr(0, 5); 
  var last5 = wallet.substr(wallet.length - 5); 
  return first5 + '...' + last5;
}

const checkWalletChanged  = () => {
  if (window.ethereum) {
      window.web3 = window.web3 = new Web3(Web3.currentProvider);
      window.ethereum.on('accountsChanged', (accounts) => {
          localStorage.setItem('wallet', accounts[0]);
          setWallet(accounts[0]);
          window.location.reload();
         // alert('changed')
      });
  }//else localStorage.removeItem('wallet')
}

const getBalance = async () => {
      if(wallet != null && window.ethereum){
       web3.eth.getBalance(wallet, function(err, result) {
        if (err) {
          console.log(err)
        } else {
          setBalance(result / (10**18))
        }
      })
      
    }
    
}



const getNetworkId = async() => {
  const provider = await detectEthereumProvider()
    if (provider) {
      const id = await provider.request({
        method: 'eth_chainId'
      })
      const idNumber = parseInt(id, 16);
      const networkId = await web3.eth.net.getId();
      //console.log(idNumber, networkId)
      if(networkId != idNumber) setIsNetwork(false)
      else setIsNetwork(true)
      //console.log('eth_chainId', idNumber)
    } else {
      console.error('Please install MetaMask!')
    }
}


//CONNECT TO METAMASK
const _connectWalletMETAMASK = async () => {
  setloading(true);
  try {
    if (window.ethereum) {
        window.web3 = window.web3 = new Web3(Web3.currentProvider);
        const result = await window.ethereum.enable();
        //console.log('catch error 1', result)
        //(result);
        if (result[0]) {
            //console.log('wallet [0]', result[0])
             localStorage.setItem('wallet', result[0]);
             setWallet(result[0]);
             setloading(false);
             window.location.reload();
         }
          return true;
        } 
     else {
        setloading(false)
        localStorage.removeItem('wallet')
        return window.open('https://metamask.io/', '_blank');  
     }
     
 } catch (error) {
     console.log('catch error', error)
     setloading(false)
 }
}

  return (
    <div>
      <div className="header-container">
       <div className="logo-cover">
         <img onClick={() => window.location.href="/"}
          className="logo" src={logo} />
          
       </div>
       <Menu token={token} />
        <div style={{marginRight: 40, display: 'flex', flexDirection:'column'}}>
          <p style={{fontSize: 10, color:'#999'}}>Trixx price</p>
          <p style={{fontSize: 14, fontWeight: 700}}>${TrixxPrice}</p>
        </div>
       <div className="connect-button-wrap">
          {isNetwork ? 
            <button onClick={_connectWalletMETAMASK} style={{fontWeight:'500', padding:'11px 20px',}}
                className="connect-wallet-btn">
                {wallet == null ? 'CONNECT WALLET' : setWalletAddress(wallet)}
            </button> :
            <button onClick={_connectWalletMETAMASK} style={{fontWeight:'700', padding:'11px 20px', background:'red'}}
                className="connect-wallet-btn">
                {'WRONG NETWORK!'}
            </button>
           }
       </div>
       
      </div>
      
    </div>
  );
}
