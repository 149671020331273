const plans = [
    {
        index: 0,
        duration: 14,
        locked: false,
        percent: 0,
        roi: 0

    },
    {
        index: 1,
        duration: 21,
        locked: false,
        percent: 0,
        roi: 0
    },
    {
        index: 2,
        duration: 28,
        locked: false,
        percent: 0,
        roi: 0
    },
    {
        index: 3,
        duration: 14,
        locked: true,
        percent: 0,
        roi: 0
    },
    {
        index: 4,
        duration: 21,
        locked: true,
        percent: 0,
        roi: 0
    },
    {
        index: 5,
        duration: 28,
        locked: true,
        percent: 0,
        roi: 0
    }
]

export default plans;