import {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Home.css';
import logo from './Img/bnb-logo.png';
import techrate from './Img/techrate.png';
import twitter from './Img/twitter.png';
import telegram from './Img/telegram.png';
import iconBlue from './Img/matrixx-icon.png';
import icon from './Img/matrixx-icon.png';
import plans from '../../Tools/plans';
import { HiChartBar } from 'react-icons/hi';
import { BsUnlock, BsLock } from 'react-icons/bs';
import { MdContentCopy} from 'react-icons/md';
import Countdown from 'react-countdown';
import airdropIcon from './Img/airdrop.png';
import closeIcon from './Img/close-icon.png';
import checkIcon from './Img/check.png';
import axios from 'axios'

import { totalStaked, getUserDownlineCount, uploadSnapshot, snapshot,
        contractBalance, userTotalStaked, terminateDeposit, checkAirdrop,
        userDividends, getUserDepositInfo, getMinimumReinvest, claimAidrop,
        getPlans, totalReferer, withdraw, getPrice, staking, reinvest,
        totalRefererWithDrawn, invest, getUserAmountOfDeposits, getUserAmountWithdrawn} from '../../Tools/methods';
import {getPriceBMX, getTokenBalance} from '../../Tools/StakingTools';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Web3 from "web3";

const web3 = new Web3(window.ethereum);


export default function Home({location}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);
  
  const [loading, setLoading] = useState(true);
  const [totalStakedBNB, settotalStaked] = useState(0);
  const [contractBalanceUSD, setContractBalance] = useState(0);
  const [userTotalStakedLocked, setUserTotalStakedLocked] = useState(0);
  const [userTotalStakedUnlocked, setUserTotalStakedUnlocked] = useState(0);
  const [userEarnings, setUserEarnings] = useState(0);
  const [userStakesList, setUserStakesList] = useState([]);
  const [planList, setPlans] = useState(plans);
  const [amount, setAmount] = useState('');
  const [focusPlan, setFocusPlan] = useState(0);
  const [totalRef, setTotalRef] = useState(0);
  const [totalRefWithdrawn, setRefWithdrawn] = useState(0);
  const [copied, setCopied] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [counter, setCounter] = useState(0);
  const [depositLength, setDepositLength] = useState(0);
  const [amountWithdrawn, setAmountWithdrawn] = useState(0);
  const [anytimeTotal, setAnytimeTotal] = useState(0);
  const [lockedTotal, setLockedTotal] = useState(0);
  const [anytimeTotalUSD, setAnytimeTotalUSD] = useState(0);
  const [lockedTotalUSD, setLockedTotalUSD] = useState(0);
  const [BNBPrice, setBNBPrice] = useState(0);
  const [BMXPrice, setBMXPrice] = useState(0);
  const [TrixxPrice, setTrixxPrice] = useState(0);
  const [walletBalanceBMX, setWalletBalanceBMX] = useState(0);
  const [walletBalanceBNB, setWalletBalanceBNB] = useState(0);
  const [userRef, setUserRef] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showLimitMsg, setShowLimitMsg] = useState(false);
  const [isGoing, setIsGoing] = useState(false);
  const [minimumReinvest, setMinimumReinvest] = useState(100000000);
  const [airdropData, setAirdropData] = useState({wallet: wallet, claimed: false, amount: 0});
  
  const refUrl = `https://matrixx.community/?ref=${wallet}`;
  
  const getBalance = async () => {
    if(wallet != null && window.ethereum){
    let web3 = new Web3('https://polygon-rpc.com');
     web3.eth.getBalance(wallet, function(err, result) {
      if (err) {
        console.log(err)
      } else {
        setWalletBalanceBNB(result / (10**18))
      }
    })
  }
}
  
  const getTotalStaked = async() => {
      //const price =  await getPrice();
      const response =  await totalStaked();
      settotalStaked(response);
      const userRef = await getUserDownlineCount(wallet);
      setUserRef(userRef);
  }

   


  const getContractBalance = async() => {
    const response =  await contractBalance();
    setContractBalance(response)
  }

  const getUserDividends = async() => {
    const price =  await getPriceBMX();
    const BMXbalance =  await getTokenBalance(wallet);
    const withdrawn =  await getUserAmountWithdrawn(wallet);
    setWalletBalanceBMX(BMXbalance);
    setBMXPrice(price);
    setAmountWithdrawn(withdrawn);
    const response =  await userDividends(wallet);
    setUserEarnings(response);
  }

  const userDepositInfo = async() => {
    let lockedAmount = 0;
    let unLockedAmount = 0;
    let anytime = 0;
    let locked = 0;
    const response =  await getUserDepositInfo(wallet);
    //console.log('response', response)
    setUserStakesList(response);

    response.forEach(async stakes => {
      //console.log(stakes)
      let amount =  parseFloat(stakes.amount) / 10 ** 18;
      if(stakes.plan < 3) lockedAmount += amount;
      else unLockedAmount += amount;

      if(stakes.plan < 3 && moment.unix(stakes.finish) > moment()) anytime += parseFloat(stakes.profit) / 10 ** 18; 
      else if(stakes.plan >= 3 && moment.unix(stakes.finish) > moment()) locked += parseFloat(stakes.profit) / 10 ** 18;
    })
    setUserTotalStakedLocked(lockedAmount);
    setUserTotalStakedUnlocked(unLockedAmount);
     const price =  await getPrice();
     setTrixxPrice(price.toFixed(6))
    setBNBPrice(price)
    setAnytimeTotalUSD(price * anytime)
    setLockedTotalUSD(price * locked);
    setAnytimeTotal(anytime);
    setLockedTotal(locked);
    setLoading(false);
  }

  const getPlanDetails = async() => {
    const response =  await getPlans();
    setPlans(response.length == 0 ? plans : response);
  }

  const sortPlanTitle = (plan) => {
     if(plan == 0) return 'BETA';
     else if(plan == 1) return 'ALPHA';
     else if(plan == 2) return 'GAMMA';
  }


  const getRefererTotals = async() => {
    const totalRefAmount =  await totalReferer(wallet);
    const totalRefWithdrawnAmount =  await totalRefererWithDrawn(wallet);
    setTotalRef(totalRefAmount);
    setRefWithdrawn(totalRefWithdrawnAmount)
    
  }

  const userAmountOfDeposits = async() => {
    const response =  await getUserAmountOfDeposits(wallet);
    setDepositLength(response);
    
  }

  const stake = async (plan) => {
    //return null;
    const walletSaved = wallet ? wallet : localStorage.getItem('wallet');
    //console.log('walletSaved, amount, plan', walletSaved, amount, plan)
    if(disabled) return null;
    if(amount < 10) return alert("A minimum of 10 TRIXX");
    setDisabled(true);
    setIsMinting(true)
    try {
       const txData = await invest(walletSaved, amount, plan);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
      setDisabled(false)
      setIsMinting(false)
    } catch (error) {
      setDisabled(false)
      setIsMinting(false)
      console.log('tx error', error);
    }
  }

  const test = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await staking(10, wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
      setDisabled(false)
    } catch (error) {
      setDisabled(false)
      setIsMinting(false)
      console.log('tx error', error);
    }
  }

  const withdrawRewards = async () => {
    if(disabled) return null;
    setDisabled(true);
    setIsMinting(true)
    try {
       const txData = await withdraw(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    setIsSuccess(true);
    setIsMinting(false)
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setDisabled(false);
      setIsMinting(false)
      console.log('tx error', error);
    }
  }

const reInvestEarnings = async () => {
    if(isMinting) return null;
    const minimumReinvest = await getMinimumReinvest();
    setMinimumReinvest(minimumReinvest)
    if(userEarnings < minimumReinvest) {
      setShowLimitMsg(true);
      setTimeout(() => {
        setShowLimitMsg(false);
      }, 5000);
      return null;
    }
    setIsMinting(true)
    setDisabled(true);
    try {
       const txData = await reinvest(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    setIsSuccess(true);
    setIsMinting(false)
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setIsMinting(false)
      setDisabled(false);
      console.log('tx error', error);
    }
  }

  const sendSnapshot = async () => {
    if(isMinting) return null;
    setIsMinting(true)
    setDisabled(true);
    try {
       const txData = await uploadSnapshot(wallet);
       await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
       console.log(hash);
    })
    setDisabled(false);
    setIsSuccess(true);
    setIsMinting(false)
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setIsMinting(false)
      setDisabled(false);
      console.log('tx error', error);
    }
  }

  const claimNow = async () => {
    if(isMinting) return null;
    if(airdropData.amount <= 0 || airdropData.claimed) return null;
    setIsMinting(true)
    setDisabled(true);
    try {
       const txData = await claimAidrop(wallet);
       await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
       console.log(hash);
    })
    setDisabled(false);
    setIsSuccess(true);
    setIsMinting(false)
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setIsMinting(false)
      setDisabled(false);
      console.log('tx error', error);
    }
  }


  

  const terminateStake = async (plan) => {
    if(isMinting) return null;
    setIsMinting(true)
    try {
       const txData = await terminateDeposit(plan, wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setIsMinting(false)
    } catch (error) {
      setIsMinting(false)
      console.log('tx error', error);
    }
  }



  useEffect(() => {
    //console.log('refAddress', location)
    if(location.search.length > 1){
      const refAddress = location.search.substr(5, location.search.length)
      localStorage.setItem('refa', refAddress);
       // console.log('refAddress Main', refAddress)
    }else {
      if(localStorage.getItem('refa') == null){
         localStorage.setItem('refa', '0xD969FdAdd122Db5bAb72b62fa7f46616FF5f2761');
          //console.log('refa local 1', localStorage.getItem('refa'))
      }
       //console.log('ref local 2', localStorage.getItem('refa'))
       //localStorage.removeItem('ref');

    }
    getTotalStaked();
    getContractBalance();
    getPlanDetails();
    userDepositInfo();
    getRefererTotals();
    getUserDividends();
    userAmountOfDeposits();
    getBalance();
    checkingAirdropData();
  },[disabled, isMinting, userEarnings])


  useEffect(() => {
    const timer =  setInterval(() => {
      getUserDividends();
      
     }, 10000);

    copiedFunc();
    return () => {
      clearInterval(timer)
    }
  })



  const copiedFunc = () => {
    if(copied)
     setTimeout(() => {
       setCopied(false)
     }, 5000);
  }

  const isActive = (finish) => {
    var d1 = new Date();
    var d2 = new Date(finish);
    return d1.getTime() > d2.getTime() ? false : true;
  }

  const formatAmount = (amount) => {
    return (parseFloat(amount) / (10 ** 18)).toFixed(2);
  }

  const isLocked = (index) => {
    if (index  == 1 || index == 2) {
       return true;
    }else return false;
  }

  const calculateDaysLeft = (start, finish) => {
      var a = moment();
      var b = moment.unix([finish]);
      const daysLeft = b.diff(a, 'days')   // =1
      a = moment.unix([start])
      b = moment.unix([finish])
      const planDays = b.diff(a, 'days')
      const daysSpent = planDays - daysLeft;
      const addSpace = 360 / planDays;
      const width = addSpace * daysSpent
      return width < 360 ? width : 360;
  }

  const getDaysLeft = (finish) => {
    var a = moment();
    var b = moment.unix([finish]);
    const daysLeft = b.diff(a, 'days')   // =1
    return daysLeft;
}

const getTimesLeft = (finish) => {
  var a = moment();
  var b = moment.unix([finish]);
  const daysLeft = b.diff(a, 'days')   // =1
  const hoursLeft = b.diff(a, 'hour')   // =1
  const minutesLeft = b.diff(a, 'minute')   // =1
  //console.log('hour', hoursLeft, minutesLeft)
  return hoursLeft <= 1 ? {value: minutesLeft, text: 'minutes'} : {value: hoursLeft, text: 'hours'};
}

const countdownTimer = () => {
  var a = moment();
  var b = moment.unix([1618229292]);
  const secondLeft = b.diff(a, 'second')   // =1
  //console.log('hour', hoursLeft, minutesLeft)
  return secondLeft * 1000;
}

  const handleChange = (e) => {
        //console.log('e.target.value', e.target.value)  
        setAmount(e.target.value);
  }

  const getAirdropData = async () => {
    //setIsChecking(true);
    setOpenModal(!openModal);
    setTimeout(async () => {
      const data = await checkAirdrop(wallet);
      if(data.wallet != "0x0000000000000000000000000000000000000000") setAirdropData(data);
      setIsChecking(false);
    }, 4000);
  }

  const checkingAirdropData = async () => {
      try {
        const data = await checkAirdrop(wallet);
      if(data.wallet != "0x0000000000000000000000000000000000000000") setAirdropData(data);
      } catch (error) {
        
      }
  }

  const registerWallet = async () => {
    if(amount == '') return alert("enter a valid polygon address")
    try {
        const result = await checkAirdrop(amount.trim());
        if(!result) return alert("enter a valid polygon address")
        if(result.wallet != "0x0000000000000000000000000000000000000000") return alert("not eligible");
        if(result.claimed) return alert("you already claimed airdrop")
        setIsGoing(true)
        const data = await snapshot(amount);
        setIsGoing(false)
        setAmount('')
    } catch (error) {
      setIsGoing(false);
      alert("error registering wallet, try again")
    }
  }

  return (
       loading ? 
        <div className="loading-div">
          <img src={icon} />
        </div>
       :
       <div>
         <div style={{position:'relative', opacity: openModal ? 0.3 : 1}}>
          <Header BNBPrice={BNBPrice} totalStaked={totalStakedBNB} setOpenModal={setOpenModal} 
          openModal={openModal} TrixxPrice={TrixxPrice}/>

          {isMinting && <p className="minting-notice"><img className="minting-img"  src={iconBlue} /> Processing...</p>}
          {isSuccess && <p className="minting-notice successful-trans"><img className="success-img"  src={checkIcon} />
          Transaction was successful!</p>}

             <div className="trixx-h1">
                     <a style={{fontWeight:'bold', color: "#d6c087"}} href="https://app.unicrypt.network/amm/quickswap-v1/pair/0x489950f82d39d5f23b7692e1c1cde72383025d49" target="_blank">
                             Liquidity Locked for 10 years</a>
                <h1 style={{marginTop: 10}}>Earn 2% Daily Investing <span className="trixx-span">Trixx</span> tokens</h1>
                <p>Polygon number one deflationary hybrid reward protocol.</p>
                  <p style={{fontWeight:'bold'}}>Paying out 2% Daily since Feb 2022 </p>
             </div>

             {/*
               <button onClick={sendSnapshot}>SNAPSHOT</button>
               */
             }

             <div className="body-container ">
                <div className="global-stats-box global-stats-wrap">
                    <h5>Your Investment</h5>
                    <hr></hr>
                    <div className="stat-box-wrap">
                      <div className="stat-box-items">
                        <p>Your stake total</p>
                        <span>
                          <CountUp
                            start={userTotalStakedUnlocked + userTotalStakedLocked}
                            end={userTotalStakedUnlocked + userTotalStakedLocked}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix=""
                            suffix=" TRIXX"
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>
                      <div className="stat-box-items">
                        <p>Value</p>
                        <span>
                        <CountUp
                            start={userTotalStakedUnlocked + userTotalStakedLocked * (TrixxPrice)}
                            end={userTotalStakedUnlocked + userTotalStakedLocked * (TrixxPrice)}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix="$"
                            suffix=""
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>                
                    </div>
                  </div>

                  <div className="global-stats-box global-stats-wrap">
                    <h5>ROI Stats</h5>
                    <hr></hr>
                    <div className="stat-box-wrap">
                      <div>
                        <p>Withdrawals</p>
                        <span>
                          <CountUp
                            start={amountWithdrawn}
                            end={amountWithdrawn}
                            separator=","
                            decimals={5}
                            decimal="."
                            prefix=""
                            suffix=" TRIXX"
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <p>Value</p>
                        <span>
                        <CountUp
                            start={amountWithdrawn * (TrixxPrice)}
                            end={amountWithdrawn* (TrixxPrice)}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix="$"
                            suffix=""
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>                
                    </div>
                  </div>

                  <div className="global-stats-box global-stats-wrap">
                    <div className="stat-box-wrap">
                      <div>
                        <p>Available to withdraw</p>
                        <span>
                          <CountUp
                            start={userEarnings}
                            end={userEarnings}
                            separator=","
                            decimals={7}
                            decimal="."
                            prefix=""
                            suffix=" TRIXX"
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <p>Value</p>
                        <span>
                         <CountUp
                            start={userEarnings * (TrixxPrice)}
                            end={userEarnings * (TrixxPrice)}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix="$"
                            suffix=""
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>                
                    </div>
                     <div className="withdraw-btn-wrap">
                       <button onClick={withdrawRewards}>Withdraw</button>
                       <button onClick={reInvestEarnings}>Compound (Re-invest)</button>
                       {showLimitMsg && <p>You can only Reinvest when your available to withdraw balance is {minimumReinvest} TRIXX or more.</p>}
                     </div>
                  </div>

             </div>
             <div className="protocol-details-wrap body-container">
                <div className="global-stats-box global-stats-wrap" style={{width: '30%'}}>
                <h5>Contract Vault</h5>
                    <hr></hr>
                    <div className="stat-box-wrap">
                      <div>
                        <p>Trixx in Vault</p>
                        <span>
                          <CountUp
                            start={contractBalanceUSD}
                            end={contractBalanceUSD}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix=""
                            suffix=" TRIXX"
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <p>Value</p>
                        <span>
                           
                        </span>
                      </div>               
                    </div>
                    
                    <div className="stat-box-wrap" style={{marginTop: 50, marginBottom: 20}}>
                      <div>
                        <p>Total Staked</p>
                        <span>
                          <CountUp
                            start={totalStakedBNB}
                            end={totalStakedBNB}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix=""
                            suffix=" TRIXX"
                            style={{
                              
                            }}
                          />
                        </span>
                      </div>
                      <div>
                        <p>Value</p>
                        <span>
                          <CountUp
                            start={totalStakedBNB * (TrixxPrice)}
                            end={totalStakedBNB * (TrixxPrice)}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix="$"
                            suffix=""
                            style={{
                              
                            }}
                          /> 
                        </span>
                      </div>               
                    </div>
                  </div>
             <div className="referal-div-wrap" style={{width: '65%', marginRight: 20}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                        <h3 style={{fontSize: 13}}>Your Referral Informations</h3>
                        {copied && <span style={{marginLeft: 50, color:'rgb(210, 132, 255)'}}>Copied!</span>}
                      </div>
                      <div className="flex-div-wrap">
                        <div className="ref-link-div">
                            <p>{refUrl}</p>
                        </div>
                        <div className="copy-div-wrap">
                        <CopyToClipboard text={refUrl}
                            onCopy={() => setCopied(true)}>
                            <MdContentCopy />
                          </CopyToClipboard>
                        </div>
                      </div>

                      <div className="flex-div-wrap" 
                          style={{alignItems:'flex-start'}}>
                        <div>
                          <div className="ref-earning-wrap">
                              <p>Total Referral Earned</p>
                              <span>
                              <CountUp
                                start={totalRef}
                                end={totalRef}
                                separator=","
                                decimals={3}
                                decimal="."
                                style={{
                                  
                                }}
                          />
                              </span>
                          </div>
                          <div className="ref-earning-wrap">
                            <p>Users Invited by You</p>
                            <span>{userRef}</span>
                        </div>
                        </div>
                        <div className="ref-earning-wrap">
                            <p>Total Referral Withdrawn</p>
                            <span>
                              <CountUp
                                start={totalRefWithdrawn}
                                end={totalRefWithdrawn}
                                separator=","
                                decimals={3}
                                decimal="."
                                style={{
                                
                                }}
                          />
                            </span>
                        </div>
                        <div className="ref-info-wrap">
                            <p>Matrixx Referral Information</p><br></br>
                            <p>You will receive:</p>
                            <div style={{marginTop: 5}}>
                              <p>
                              5% from each level 1 referral deposits <br></br>
                              2.5% from each level 2 referral deposits<br></br>
                              0.5% from each level 3 referral deposits<br></br>
                              </p>
                              <br></br>
                              <p>
                                <em>
                                Note! You need to have at least <br></br> 1 deposit to start receive earnings
                                </em>
                              </p>
                            </div>
                        </div>
                      </div>
                   </div> 

                </div>  

                <div className="deposit-body-container">
                  <h5 style={{marginLeft: 20, marginTop: 40, marginBottom: 10, fontSize: 14}}>DEPOSITS</h5>
                  <div className="deposit-container" style={{marginBottom: 50}}>
                    {userStakesList.toReversed().map((item, index) => (
                      !isLocked(item.plan) ?
                      <div key={item.plan} className="deposit-box" style={{opacity: getTimesLeft(item.finish).value >= 0  ? 1 : 0.2}}>
                       <div className="flex-div-wrap-header">
                         <h3 style={{fontSize:12}}>{sortPlanTitle(item.plan)}</h3>
                         <BsUnlock size={17} color="rgb(221, 221, 250)" />
                       </div>
                       <div className="days-locked" style={{marginBottom: 0}}>
                           <div style={{width: 50, height: 50}}>
                             <h3 style={{fontSize: 22}}>
                               {getDaysLeft(item.finish) > 0 ? getDaysLeft(item.finish) : 0}
                             </h3>
                             <p style={{fontSize: 8, bottom: 10}}>DAYS</p>
                           </div>
                       </div>

                       <div className="flex-div-wrap">
                            <div className="deposit-staked">
                                <span style={{color:"rgb(221, 221, 250)"}}>{formatAmount(item.amount)}</span>
                                <p style={{fontSize: 12}}>TRIXX</p>
                            </div>
                            <div className="deposit-staked">
                                <span style={{color:"rgb(221, 221, 250)"}}>{formatAmount(item.profit)}</span>
                                <p style={{fontSize: 12}}>TRIXX</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div key={item.plan} className="deposit-box" style={{opacity: getTimesLeft(item.finish).value >= 0  ? 1 : 0.2}}>
                       <div className="flex-div-wrap-header">
                         <h3 style={{fontSize:12}}>{sortPlanTitle(item.plan)}</h3>
                         <BsLock size={17} color={item.plan == 1 ? "#f2bd84": "rgb(210, 132, 255)"} />
                       </div>
                       <div className="days-locked" style={{marginBottom: 0}}>
                           <div style={{width: 50, height: 50,}}>
                             <h3 style={{fontSize: 22, color: item.plan == 1 ? "#f2bd84": "rgb(210, 132, 255)"}}>
                              {getDaysLeft(item.finish) > 0 ? getDaysLeft(item.finish) : 0}
                             </h3>
                             <p style={{fontSize: 8, bottom: 10}}>DAYS</p>
                           </div>
                       </div>

                       <div className="flex-div-wrap">
                            <div className="deposit-staked">
                                <span style={{color: item.plan == 1 ? "#f2bd84": "rgb(210, 132, 255)"}}>{formatAmount(item.amount)}</span>
                                <p style={{fontSize: 12}}>TRIXX</p>
                            </div>
                            <div className="deposit-staked">
                                <span style={{color: item.plan == 1 ? "#f2bd84": "rgb(210, 132, 255)"}}>{formatAmount(item.profit)}</span>
                                <p style={{fontSize: 12}}>TRIXX</p>
                            </div>
                        </div>
 
                        <div className="timeleft" style={{textAlign:'center', marginTop: -30, fontSize:10}}>
                           <Countdown date={moment.unix([item.finish])}></Countdown>
                        </div>
                    </div>
                    ))
                    }
                  </div>
                </div>

                <div className="footer-info-wrap">
                   <p>powered by <span>Matrixx.community</span></p>
                </div>   
   
            </div>
               {openModal &&
                <div className="airdrop-box-wrap">
                  <img 
                     className="airdrop-box-icon" src={airdropIcon} />
                     <img 
                     className="airdrop-close-icon" src={closeIcon} onClick={() => setOpenModal(false)} />
                   <h3>
                     <CountUp
                        start={0}
                        end={parseFloat(airdropData.amount / (10 ** 18))}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        suffix=""
                        style={{
                           fontStyle: "normal",
                           fontSize: 50,
                           opacity: 1
                        }}
                      />
                   </h3>
                   <h5>trixx</h5>
                   {isChecking ?
                    <div class="lds-hourglass"></div>
                    :
                    <div className="wallet-address-wrap">
                        <p>{airdropData.wallet}</p>
                    </div>
                   }
                   {airdropData.claimed ? 
                      <button style={{cursor: 'none'}}>You already claimed your Token. </button>:
                      <button onClick={claimNow}>Claim</button>
                    }
                </div>  
               }

           </div>
  );
}
