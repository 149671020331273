import  { stakingABI } from './contractABI';
import  { contractABIms} from './contractABIms';
import  { contractABI,} from './matrixxContractABI';
import  { pancakeRouterABI } from './pancakeRouterABI';
import plans from './plans';
import formatBigNumber from './formatBigNumber';
import Web3 from "web3";
import { createClient } from '@supabase/supabase-js';
const web3 = new Web3('https://polygon-rpc.com');
const BNBMinterContract = '0x57293650501863Da6482cfda4C45dfc253Db2d3D';
const MaticstakerContract = '0xda3f4d9509c1881f0661bc943db23024b7de2f82';

export const supabase = createClient(
    "https://cybkymeindmsukzrahzs.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN5Ymt5bWVpbmRtc3VrenJhaHpzIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY0NjU4NjkxNCwiZXhwIjoxOTYyMTYyOTE0fQ.ZyZAODK3uobnWcAb6xfAtboDDJFNqQA4TNho8A6Ocyg"
  );

const  BancaABI = contractABI;

export async function totalStaked() {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.totalStaked().call();
        //console.log('total staked', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function contractBalance() {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getContractBalance().call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function userTotalStaked(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getUserTotalDeposits(wallet).call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function userDividends(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getUserDividends(wallet).call();
        let ref = await contract.methods.getUserReferralBonus(wallet).call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18)) + parseFloat(ref / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function getUserAmountWithdrawn(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getUserAmountWithdrawn(wallet).call();
        //console.log('Contract balance', response)
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function getUserDepositInfo(wallet) {
    const userStakes = [];
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let deposits = await contract.methods.getUserAmountOfDeposits(wallet).call();
       // console.log('deposits', deposits)
        for (let index = 0; index < deposits; index++) {
            let response = await contract.methods.getUserDepositInfo(wallet, index).call();
            if(response)
            userStakes.push(response);  
            //console.log('users stakes ', userStakes)
        }
        //console.log('users stakes ', userStakes)
        return userStakes;
    } catch (error) {
        console.log(error);
        return userStakes;
    }
}

export async function getUserAmountOfDeposits(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let deposits = await contract.methods.getUserAmountOfDeposits(wallet).call();
        return deposits;
    } catch (error) {
        console.log(error);
    }
}

export async function getUserDownlineCount(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let ref = await contract.methods.getUserDownlineCount(wallet).call();
        //console.log('ref', ref['0'])
        return ref['0']
    } catch (error) {
        console.log(error);
    }
}


export async function getPlans() {
    const listOfPlans = []
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        for (let index = 0; index < plans.length; index++) {
            let response = await contract.methods.getPercent(index).call();
            let result = await contract.methods.getResult(index, '1000000000000000000').call();
            const planObj = {...plans[index], percent: response / 10, roi: (result.profit / (10 ** 18)) * 100}
            listOfPlans.push(planObj);
        }

        return listOfPlans;
        
    } catch (error) {
        console.log(error);
        return listOfPlans;
    }
}

export async function totalReferer(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getUserReferralTotalBonus(wallet).call();
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function totalRefererWithDrawn(wallet) {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.getUserReferralWithdrawn(wallet).call();
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}


export async function getMinimumReinvest() {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.MIN_REINVEST_AMOUNT().call();
        //console.log(response / (10 ** 18))
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}


export async function getMinimumInvest() {
    try {
        const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
        let response = await contract.methods.INVEST_MIN_AMOUNT().call();
        //console.log(response / (10 ** 18))
        return parseFloat(response / (10 ** 18));
    } catch (error) {
        console.log(error)
    }
}

export async function invest(wallet, amount, plan) {
    const ref = localStorage.getItem('refa');
    amount = formatBigNumber(Math.ceil(amount * (10 ** 18))).toString();
     //console.log('num 2', amount, wallet, ref, plan);
     const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
    let data = await contract.methods.invest(ref, plan).encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: amount, data}
    return transationData;
}

export async function withdraw(wallet) {
     const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
    let data = await contract.methods.withdraw().encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
    return transationData;
}

export async function reinvest(wallet) {
    const plan = 0;
     const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
    let data = await contract.methods.reInvestEarnings(plan).encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
    return transationData;
}

const qualifiedWallets = []

async function getList() {
    const body = await supabase.from('drops').select('*').range(0, 5);
    //console.log("ALL LIST", body.data[0].amount)
}

//getList() 

export async function uploadSnapshot(wallet) {
    //const qualifiedWallets = [{amount: "100000000000000000000000", wallet: "0x4f7Bd1638820600F9519286a5ed40F1A852e3bB3", claimed: false}]
    const arr = [];
    const body = await supabase.from('airdrop').select('*').range(2422, 2423);
    body.data.forEach(async item => {
       // const result = await checkAirdrop(item.wallet)

            //console.log("wallets", result.wallet)
            arr.push({amount: item.amount, wallet: item.wallet, claimed: false});
 
         
    });
    const qualifiedWallets = arr;
    //console.log("qualifiedWallets", qualifiedWallets)
    const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
    //console.log("lengt", qualifiedWallets)
    let data = await contract.methods.snapshot(qualifiedWallets).encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '20000000', value: '0', data}
    return transationData;
}

export async function claimAidrop(wallet) {
    const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
    let data = await contract.methods.claimAirdrop().encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
    return transationData;
}

export async function checkAirdrop(wallet) {
        try {
            const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
            let data = await contract.methods.checkAirdrop(wallet).call();
            //console.log("airdrop data", data)
            return data;
        } catch (error) {
           // console.log("airdrop data", error)
           return null
        }
}



export async function terminateDeposit(plan, wallet) {
    const contract = new web3.eth.Contract(BancaABI , BNBMinterContract);
   let data = await contract.methods.terminateDeposit(plan).encodeABI();
   //console.log(data)
   const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
   return transationData;
}

export async function getPriceBNB() {
    try {
        const contract = new web3.eth.Contract(pancakeRouterABI, '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff');
        //console.log('price lp ')
        const path = [
                '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
                '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
                ]
        let price = await contract.methods.getAmountsOut('1000000000000000000', path).call()
            price = price[1] / (10 ** 18);
            //console.log('price lp ', price.toFixed(2))
        return price;
    } catch (error) {
        
    }
}

export async function getPrice() {
    try {
        const contract = new web3.eth.Contract(pancakeRouterABI, '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff');
        //console.log('price lp ')
        const path = [
                '0x57293650501863Da6482cfda4C45dfc253Db2d3D',
                '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
                ]
        let priceMatic = await contract.methods.getAmountsOut('1000000000000000000', path).call();
        //console.log('price NEW 2 ', priceMatic[1])
        const path2 = [
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
            ]
    let price = await contract.methods.getAmountsOut(priceMatic[1], path2).call()
           //console.log('price NEW ', price[1])
            price = price[1] / (10 ** 6);
            const formatedPrice = price
            //console.log('price NEW ', formatedPrice)
        return formatedPrice;
    } catch (error) {
        console.log('price error ', error)
    }
}

//'0x20DB0968c90806710f99FF01D5bE8179Bff1bb17'

export async function staking(amount, wallet) {
    const ref = localStorage.getItem('refa');
    amount = formatBigNumber(Math.ceil(amount * (10 ** 18))).toString();
     //console.log('num 2', amount, wallet, ref, plan);
     const contract = new web3.eth.Contract(stakingABI, BNBMinterContract);
    let data = await contract.methods.invest(amount).encodeABI();
    //console.log(data)
    const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
    return transationData;
}

export const insertData = async (amount, wallet) => {

    const {data: drops, error} = await supabase.from('airdrop').select('*')
    .eq('wallet', wallet)
        .single()
        if(!drops) {
            const response = await supabase.from('airdrop').insert({wallet, amount, claimed: false})
           //console.log("response INSERT", response)
        }
}


export async function snapshot(wallet) {
    //const {data: drops, error} = await supabase.from('drops').select('*').eq('wallet', wallet).single();

    try {
        const contract = new web3.eth.Contract(contractABIms, MaticstakerContract);
            let amount = 0;
            let deposits = await contract.methods.getUserAmountOfDeposits(wallet).call();
            //console.log('deposit height', deposits)
            for (let index = 0; index < deposits; index++) {
            let response = await contract.methods.getUserDepositInfo(wallet, index).call();
             if(response && response.plan > 2 && parseFloat(response.amount) > 0) {
                 if (response.plan == 3 ) {
                    amount += (parseFloat(response.amount) / 10 );
                    let num = parseFloat(amount / (10 ** 18))

                 }
                 else if (response.plan == 4 ) {
                    amount += (parseFloat(response.amount) / 5); 
                    let num = parseFloat(amount / (10 ** 18))
                 }
                 else if (response.plan == 5 ) {
                    amount += parseFloat(response.amount); 
                    let num = parseFloat(amount / (10 ** 18))
                 }
             }          
           }
 
           //arr.push({amount: formatBigNumber(Math.ceil(amount)).toString(), wallet: user.wallet, claimed: false});
          const  amountStr = formatBigNumber(Math.ceil(amount)).toString();
           if(amount > 0) {
              await insertData(amountStr, wallet)
              //console.log("SUM QUALIFIED", amountStr, wallet);
           }else console.log("SUM", amountStr, wallet);
    

    } catch (error) {
        console.log(error);
    }
}





// export async function uploadSnapshotMANUAL(wallet, qualifiedWallets) {
//     //const qualifiedWallets = [{amount: "100000000000000000000000", wallet: "0x4f7Bd1638820600F9519286a5ed40F1A852e3bB3", claimed: false}]

//     const contract = new web3.eth.Contract(BancaABI, BNBMinterContract);
//     //console.log("lengt", qualifiedWallets)
//     let data = await contract.methods.snapshot(qualifiedWallets).encodeABI();
//     //console.log(data)
//     const transationData = { from: wallet, to: BNBMinterContract, gas: '530000', value: '0', data}
//     return transationData;
// }



//totalStaked()