import {useState, useEffect, useMemo} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import icon from './Img/banca-icon.png';
import plans from '../../Tools/plans';
import iconBlue from './Img/matrixx-icon.png';
import checkIcon from './Img/check.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Token.css';
import logo from './Img/bnb-logo.png';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import Countdown from 'react-countdown';
import maticIcon from './Img/polygon.svg';
import swapIcon from './Img/swap.png';
import {
        getAllowance, 
        approveContract, 
        userDividends, 
        getPercent, 
        getProfit,
        getUserDepositInfo,
        getCreditBalance,
        getCreditPaid,
        withdraw,
        invest,
        getCredit,
        terminate,
        getTokenBalance,
        getPriceBMX
        } 
        from '../../Tools/StakingTools';
        import { totalStaked, getPriceBNB, getPrice, getPlans, getMinimumInvest
          } from '../../Tools/methods';

import Web3 from "web3";
const web3 = new Web3(window.ethereum);

const plansData = [
  {
    name: "Beta",
    text: "This is a 365 days flex plan in which you can withdraw your rewards anytime. It has a fixed daily reward of 2%.",
    bgColor: "#3e3099",
    color: "#fff",
    plan: 0,
    class:"plan-selector-beta",
    duration: 365,
    roi: 730
  },
  {
    name: "Alpha",
    text: "This is 90 days locked plan which auto compounds daily at a fixed rate of 1.9% return on investment.",
    bgColor: "#7b1afa",
    color: "#fff",
    plan: 1,
    class:"plan-selector-alpha",
    duration: 90,
    roi: 444
    
  },
  {
    name: "Gamma",
    text: "This is 180 days locked plan which auto compounds daily at a fixed rate of 1.2% return on investment.",
    bgColor: "#a809f9",
    color: "#fff",
    plan: 2,
    class:"plan-selector-gamma",
    duration: 180,
    roi: 756

  }
]

const planList = [];

const getPlanDetails = async() => {
    const response =  await getPlans();
    let planRes = response.length == 0 ? plans : response;
    //console.log("planRes1", planRes)

    planRes.forEach((plan, index )=> {
    const data = {...plansData[0], duration: plan.duration, roi: plan.roi }
    //console.log("planRes2", planRes)
    planList.push(data)
    });
  }

getPlanDetails()

export default function Staking({}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState(0);
  const [disabled, setDisabled] = useState(0);
  const [userEarnings, setUserEarnings] = useState(0);
  const [percent, setPercent] = useState(0);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState(0);
  const [depositInfo, setDepositInfo] = useState(null);
  const [credit, setCredit] = useState(0);
  const [creditReceived, setCreditReceived] = useState(0);
  const [BMXBalance, setBMXBalance] = useState(0);
  const [price, setPrice] = useState(0);
  const [BNBPrice, setBNBPrice] = useState(0);
  const [totalStakedBNB, settotalStaked] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(plansData[0]);
  const [isMinting, setIsMinting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [MIN_INVEST_AMOUNT, setMIN_INVEST_AMOUNT] = useState(0);
  const [TrixxPrice, setTrixxPrice] = useState(0);
  let [counter, setCounter] = useState(0);

  const getTokenAllowance = async () => {
       const allowance =  await getAllowance(wallet);
       setAllowance(allowance);
       const minimum = await getMinimumInvest();
       setMIN_INVEST_AMOUNT(minimum);
  }

  const getUserDividends = async() => {
    const response =  await userDividends(wallet);
    setUserEarnings(response);
  }

  const getPlanPercent = async() => {
    const response =  await getPercent();
    setPercent(response);
  }

  const getUserInfo = async() => {
    const response =  await getUserDepositInfo(wallet);
    setDepositInfo(response);
  }

  const getCreditData = async() => {
    const price =  await getPriceBNB();
    const trixxPrice =  await getPrice();
    setTrixxPrice(trixxPrice.toFixed(4))
    setBNBPrice(price)
    const response =  await totalStaked();
    settotalStaked(response);
    const balance =  await getCreditBalance(wallet);
    const paid =  await getCreditPaid(wallet);
    setCredit(balance);
    setCreditReceived(paid);
  }

  const getBMXBalance = async() => {
    const balance =  await getTokenBalance(wallet);
    if(balance) setBMXBalance(balance);
    setLoading(false);
  }

  const getBMXPrice = async() => {
    const price =  await getPrice();
    setBNBPrice(price);
  }

  const handleChange = async (e) => {
      setAmount(e.target.value);
      const response =  await getProfit(e.target.value);
      setProfit(response);
  }

  const stake = async () => {
    if(disabled || parseFloat(amount) < 1 || BMXBalance < parseFloat(amount)) return null;
    if(parseFloat(amount) < MIN_INVEST_AMOUNT) return null;
    setDisabled(true);
    setIsMinting(true)
    try {
       const txData = await invest(wallet, selectedPlan.plan, amount);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    setIsMinting(false)
    setAmount(0);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setDisabled(false);
      setIsMinting(false)
      console.log('tx error', error);
    }
  }

  const getCreditFunc = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await getCredit(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log('tx error', error);
    }
  }
  
  const terminateStake = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await terminate(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log('tx error', error);
    }
  }


  const withdrawRewards = async () => {
    if(disabled) return null;
    setDisabled(true);
    setIsMinting(true)
    try {
       const txData = await withdraw(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
        console.log(hash);
    })
    setDisabled(false);
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    },1000);
    } catch (error) {
      setDisabled(false);
      setIsMinting(false)
      console.log('tx error', error);
    }
  }

  const approve = async () => {
    if(disabled) return null;
    setDisabled(true);
    try {
       const txData = await approveContract(wallet);
       const transactionData = await web3.eth.sendTransaction(txData).on('transactionHash', (hash) => {
       console.log(hash);
    })
      setDisabled(false);
    } catch (error) {
      setDisabled(false)
      console.log('tx error', error);
    }
  }


  useEffect(() => {
    getTokenAllowance();
    getUserDividends();
    getPlanPercent();
    getUserInfo();
    getCreditData();
    getBMXBalance();
    getBMXPrice();
  },[disabled, counter])

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter++);
    }, 10000);
    return () => {
      clearInterval(timer);
    }
  }, [])


  const getTimesLeft = () => {
    var a = moment();
    var b = moment.unix([1617789338]);
    const secondLeft = b.diff(a, 'second')   // =1
    //console.log('hour', hoursLeft, minutesLeft)
    return secondLeft * 1000;
  }
  
  const switchPlan = (plan) => {
    setSelectedPlan(plan)
  }

  const maxBalance = () => {
      setAmount(BMXBalance);
  }
  
  
  return (
        
       <div>
          <Header BMXbalance={BMXBalance} token={true} BNBPrice={BNBPrice} 
          totalStaked={totalStakedBNB} TrixxPrice={TrixxPrice} />
          
          <div className="trixx-staking-container">
          {isMinting && <p className="minting-notice"><img className="minting-img"  src={iconBlue} /> Processing...</p>}
          {isSuccess && <p className="minting-notice successful-trans"><img className="success-img"  src={checkIcon} /> 
              Transaction was successful!</p>}
              <div className="input-staking-wrap">
                 <div>
                   <h2>Invest & Earn</h2>
                  
                 </div>
                 <p onClick={maxBalance}>Balance 
                  <span>
                    <CountUp
                      start={BMXBalance}
                      end={BMXBalance}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=" "
                      suffix=" TRIXX"
                      style={{
                        
                      }}
                    />
                   </span>
                 </p>
                 <div className="plan-selector-wrap">
                   {plansData.map(plan => (
                      <button onClick={() => switchPlan(plan)}
                        className={selectedPlan.plan != plan.plan ? plan.class + "-off" : plan.class}>{plan.name}</button>
                   ))}
                 </div>
                 <div className="plan-brief" style={{background: selectedPlan.bgColor}}>
                    <p>
                     {selectedPlan.text}
                    </p>
                 </div>
                 <p style={{textAlign: "right", width:"76%", marginBottom: -15, fontSize: 12,
                    color: "skyblue", opacity: 0.5}}>{" minimum = " + MIN_INVEST_AMOUNT + " Trixx"}</p>
                 <input value={amount} onChange={(e) => setAmount(e.target.value)} />

                 <div>
                     <p>ROI at end of plan</p>
                     <h2>
                      <CountUp
                        start={parseFloat((selectedPlan.roi * amount) / 100).toFixed(2)}
                        end={parseFloat((selectedPlan.roi * amount) / 100).toFixed(2)}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=" "
                        suffix=" TRIXX"
                        style={{
                         fontSize: 25 
                        }}
                      />
                     </h2>
                 </div>
                 <button onClick={stake}
                    className="trixx-deposit-button">Deposit</button>
              </div>
          </div>

          
         
        </div>
  );
}
