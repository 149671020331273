import {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import icon from './Img/logo-green.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Charts.css';
import logo from './Img/bnb-logo.png';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import {tokensList} from '../../Tools/coinsAPI';


export default function Charts({}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);
  const [tokenDataList, setTokenDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTokeDatas = async () => {
       const tokens  = await tokensList();
       //console.log(tokens)
       setTokenDataList(tokens);
       setLoading(false)
  }
 
  useEffect(() => {
    getTokeDatas();
  },[])
  
  return (
        loading ? 
         <div className="loading-div">
           <img src={icon} />
         </div>
       :
       <div>
          <Header />
          <div className="charts-container">
             <div className="charts-wrap">
               {
              //  <div className="charts-search-wrap">
              //    <input /> 
              //    <button>SEARCH</button>
              //  </div>
               }
               <div className="charts-links-wrap">
                 <button>Mint BNB</button>
                 <button>TOKEN TRACKER</button>
               </div>

               <div className="charts-box charts-box-header">
                    <p style={{width: '10%'}}>
                      
                    </p>
                    <p style={{width: '15%'}}>
                      Icon
                    </p>
                    <h5>Token</h5>
                    <p>Symbol</p>
                    <p style={{}} className="charts-price-wrap">
                        <span>Price</span>
                    </p>
                    <p style={{}} className="charts-price-wrap">
                        <span>1hour</span>
                    </p>
                    <p style={{}} className="charts-price-wrap">
                        <span>1day</span>
                    </p>
                    <p style={{}} className="charts-price-wrap">
                        <span>7days</span>
                    </p>
                    <p style={{}}>Cs</p>
                    <p style={{}}>Market cap</p>
                </div>
                
                {tokenDataList.map(item => (
                   <div key={item.id} className="charts-box">
                     <p style={{width: '10%'}}>
                       <MdFavoriteBorder color="#555" size={20} />
                     </p>
                     <p style={{width: '15%'}}>
                       <img src={item.symbol == "btcb" ? 
                       "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png" :
                         item.symbol == "wbnb" ? 
                          "https://cdn.coinranking.com/a9FoQVHLG/wbnb.svg?size=48x48" :
                          item.image} />
                     </p>
                     <h5>{item.name}</h5>
                     <p>{item.symbol.toUpperCase()}</p>
                     <p style={{color:'#1adec2'}} className="charts-price-wrap">
                         <span>
                           <CountUp
                            start={item.current_price}
                            end={item.current_price}
                            separator=","
                            decimals={2}
                            decimal="."
                            prefix="$"
                            suffix=""
                            />
                          </span>
                     </p>
                     <p style={{color:item.price_change_percentage_1h_in_currency > 0 ? '#1adec2' : '#f35f65'}} 
                        className="charts-price-wrap">
                     { item.price_change_percentage_1h_in_currency > 0 ? 
                           <FaLongArrowAltUp style={{marginTop:1}} size={11} />
                           :
                           <FaLongArrowAltDown style={{marginTop:2}} size={11} />}
                         <span>{item.price_change_percentage_1h_in_currency.toFixed(2)}%</span>
                     </p>
                     <p style={{color:item.price_change_percentage_24h > 0 ? '#1adec2' : '#f35f65'}} 
                     className="charts-price-wrap">
                         { item.price_change_percentage_24h > 0 ? 
                           <FaLongArrowAltUp style={{marginTop:1}} size={11} />
                           :
                           <FaLongArrowAltDown style={{marginTop:2}} size={11} />}
                         <span>{item.price_change_percentage_24h.toFixed(2)}%</span>
                     </p>
                     <p style={{color: item.price_change_percentage_7d_in_currency > 0 ? '#1adec2' : '#f35f65'}} 
                         className="charts-price-wrap">
                           {item.price_change_percentage_7d_in_currency > 0 ? 
                           <FaLongArrowAltUp style={{marginTop:1}} size={11} />
                           :
                           <FaLongArrowAltDown style={{marginTop:2}} size={11} />
                           }
                         <span>{item.price_change_percentage_7d_in_currency.toFixed(2)}%</span>
                     </p>
                     <p style={{color: '#ffa700', fontWeight: 300}}>
                         <CountUp
                            start={item.circulating_supply}
                            end={item.circulating_supply}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix=""
                            suffix=""
                            /></p>
                     <p style={{color: '#fad27f', fontWeight: 300}}><CountUp
                              start={item.market_cap}
                              end={item.market_cap}
                              separator=","
                              decimals={0}
                              decimal="."
                              prefix="$"
                              suffix=""
                              />
                            </p>
                 </div>
                 
                ))}

                
             </div>
          </div>
         <Footer />
      </div>
  );
}
