import {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './About.css';
import logo from './Img/bnb-logo.png';


export default function About({}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);

  
  return (
       <div>
          <Header />
          <div className="about-container">
              <p style={{fontSize: 25, fontWeight: '700', paddingLeft:13, marginBottom: 20, marginTop:10, color: "rgb(223, 211, 255)"}}>BNBMinter - An Investment platform built on BSC</p>
              <p className="minter-words" style={{}}><strong><em>BNBminter is a fork of 
               BNBstake.app but better. Contract is the same with two major tweaks to increase the lifespan of the contract.</em></strong></p>
              <p className="minter-words"><strong><em><span style={{}}>1. We changed the daily percent increment from 0.5% to 0.2%. This will limit the daily mint rates for new stakes and expands the longevity of the contract reserves.</span></em></strong></p>
              <p className="minter-words"><span style={{}}><strong><em>2. We also included withdrawal fees of 5% which are re-deposited into the contract to increase its reserves.&nbsp;</em></strong></span></p>
              <p style={{marginBottom: 20}} className="minter-words"><strong><em><span>This onchain investment programme is very secure, the contract has been studied and verified to be 100% safe. With the tweak we included, the contract will be able to sustain itself for a very long time.</span></em></strong></p>
              <div className="about-div-wrap">
                <h5 className="about-para-title"><strong>Incentives</strong></h5>
                <p> &bull; Basic interest rate (only for new deposits): +0.2% every 24 hours </p>
                <p>&bull; Total income (based on your tariff plan): from 5% to 8% daily + Basic interest rate (growing daily)</p>
                <p> &bull; Minimal deposit: 0.05 BNB and no maximal limit </p>
                <p>&bull; Earnings every moment, withdraw any time if you use capitalization of interest you can withdraw only after end of your deposit Investment Plans We have two types of tariff plans with different periods and percentages of profitability </p>
              </div>
              <div className="about-div-wrap">
              <h5 className="about-para-title"><strong>Plan Type №2 (Yellow) - Withdraw profit Anytime</strong></h5>
                <p>&bull; Plan 1 - (14 days) - 8% per day - 112% profit </p>
                <p>&bull; Plan 2 - (21 days) - 6.5% per day - 136% profit </p>
                <p>&bull; Plan 3 - (28 days) - 5% per day - 140% profit Explanations: </p>
                <p>1. Starting percentages (what you see on the slide) increase every day by 0.2% For example: on plan 1 on day 20 of the project, the starting percentage will already be equal to 14% per day (20 * 0.2 + 8) </p>
                <p>2. The percentage is fixed at the start of the plan. For example: on the 12th day of your plan, the percentage will be the same as it was at the very beginning of its work. </p>
                <p>Investment Plans We have two types of tariff plans with different periods and percentages of profitability </p>
              </div>
              <div className="about-div-wrap">
                <h5 className="about-para-title"><strong><>Plan Type №2 (green) - Withdraw profit end of plan + use capitalization of interest</></strong></h5>
                <p> &bull; Plan 4 - (14 days) - 8% per day - 193% profit </p>
                <p>&bull; Plan 5 - (21 days) - 6.5% per day - 275% profit </p>
                <p>&bull; Plan 6 - (28 days) - 5% per day - 292% profit Explanations: </p>
                <p>1. Starting percentages (what you see on the slide) increase every day by 0.2% </p>
                <p>2. This plan type use capitalization of interest (every day) to calculate the percentage of profit. example: in 1 day you get, + 8% for the amount of 1 BNB = 1.08 BNB. On the second day, you will already receive 8.2% on the amount from the previous day 1.08 * 8.2% = 1.16856 BNB. The next day, 8.4% on the amount of the day passed, and so on. </p>
                <p>3. The profit percentage is growing every day. </p>
              </div>
              <div className="about-div-wrap">
                <h5 className="about-para-title"><strong><span style={{fontSize: 20}}>Referral System Get additional income by inviting people to follow your link You will receive</span></strong>
                </h5>
                  <p>&bull; 5% from each level 1 referral deposits </p>
                  <p>&bull; 2.5% from each level 2 referral deposits </p>
                  <p>&bull; 0.5% from each level 3 referral deposits Explanations: </p>
                  <p>1. You need to have at least 1 deposit to start receive earnings </p>
                  <p>2. You need to use your referral link to record the people you refer </p>
                  <p>3. Your reward is credited to a separate place in the smart contract and can be withdrawn at any time by pressing a button &laquo;Mint BNB&raquo; </p>
                  <p>4. It is forbidden to use illegal methods of advertising your referral link Smart Contract Smart-Contract is safe for use in the Binance Smart Chain main network </p>
              </div>
              <div className="about-div-wrap">
                  <h5 className="about-para-title"><strong><span style={{fontSize: 20}}>Contract Information & Audit</span></strong>
                    </h5>
                  <p>&bull; Contract Address: <strong><a style={{color:'#fff'}} href="https://bscscan.com/address/0x734Bf4623296dAb55de6Fced4D1395e6638e9D65" target="_blank">https://bscscan.com/address/0x734Bf4623296dAb55de6Fced4D1395e6638e9D65</a></strong></p>
                  <p>&bull; Audit Report: <strong><a style={{color:'#fff'}} href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/BNBMinter.pdf
                        " target="_blank">https://github.com/TechRate/Smart-Contract-Audits/blob/main/BNBMinter.pdf
                        </a></strong></p>
                  <p> &bull; Working on Binance Smart Chain fast and secured network by Binance </p>
                  <p>&bull; The code was tested with compatible compilers and simulate manually reviewed for all commonly known and specific vulnerabilities. </p>
              </div>
              <div className="about-div-wrap">
              <h5 className="about-para-title"><strong><span style={{fontSize: 20}}>Connecting to BNBMinter With Metamask</span></strong>
                    </h5>
                  <p>&bull; MetaMask can be downloaded on Chrome and Firefox, or on iOS and Android if you&rsquo;re a mobile user here:<a style={{color: '#fff', fontWeight: '700'}} href="https://metamask.io/download.html" target="_blank"> Download Metamask</a></p>
                  <p>&bull; Next, follow along with the setup specified by the app. Go ahead and click Create a Wallet. Write down the backup seed phrase somewhere secret </p>
                  <p>&bull; Select Settings from the dropdown menu </p>
                  <p>&bull; On the Settings page, we want to locate the Networks menu </p>
                  <p>&bull; Click Add Network in the top-right corner to manually add the Binance Smart Chain one (see next page)</p>
                  <p> &bull; Once you Save the Network and return to the main view and select Binance Smart Chain Connecting to BNBStake </p>
                  <p>Network Name: Binance Smart Chain&nbsp;</p>
                  <p>Network New RPC URL: https://bsc-dataseed.binance.org/ </p>
                  <p>ChainID: 56 Symbol: BNB </p>
                  <p>Block Explorer URL: <a style={{color: '#fff', fontWeight: '700'}} href="https://bscscan.com" target="_blank">https://bscscan.com</a></p>
              </div>
              <div className="about-div-wrap">
              <h5 className="about-para-title"><strong><span style={{fontSize: 20}}>Contact Informations</span></strong>
                    </h5>
              <p>Telegram Group: <a style={{color: '#f1779b', fontWeight: '700'}} href="https://t.me/bnbminter">https://t.me/bnbminter</a></p>
              <p>Telegram Group -  SPANISH: <a style={{color: '#f1779b', fontWeight: '700'}} href="https://t.me/bnbminter-spanish">https://t.me/bnbminter-spanish</a></p>
               <p>Website: <a style={{color: '#f1779b', fontWeight: '700'}} href="https://www.bnbminter.app">www.bnbminter.app</a></p>
              </div>
           </div>
         <Footer />
      </div>
  );
}
