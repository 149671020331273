import {useState, useEffect, useMemo} from 'react';
import CountUp from 'react-countup';
import moment from 'moment';
import icon from './Img/logo-green.png';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './TokenScreen.css';
import logo from './Img/bnb-logo.png';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import {tokensList} from '../../Tools/coinsAPI';
import { Chart } from 'react-charts'

export default function TokenScreen({}) {
  const walletSaved = localStorage.getItem('wallet');
  const [wallet, setWallet] = useState(walletSaved);

  const [tokenDataList, setTokenDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTokeDatas = async () => {
       
  }
 
  useEffect(() => {
   
  },[])

  const data = useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      },
      {
        label: 'Series 2',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      }
    ],
    []
  )
 
  const axes = useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )
  
  return (
        loading ? 
         <div className="loading-div">
           <img src={icon} />
         </div>
       :
       <div>
          <Header />
          <div className="token-screen-search-wrap">
               <div>
                  <input placeholder="Paste Contract Address" /> 
               </div>
               <div className="token-screen-details-wrap">
                  {
                    // <div className="token-screen-price-wrap">
                    //   <span>Price</span>
                    //   <h5>$45.00</h5>
                    // </div>
                  }
                  <h1>COMING SOON!</h1>
                  <Chart data={data} axes={axes} />
               </div>
          </div>
      </div>
  );
}
